import axios from 'axios';
import { getIds, getObject } from 'utils';
import produce from 'immer';

export const FETCH_CRAFTS_REQUEST = 'FETCH_CRAFTS_REQUEST';
export const FETCH_CRAFTS_SUCCESS = 'FETCH_CRAFTS_SUCCESS';
export const FETCH_CRAFTS_FAILURE = 'FETCH_CRAFTS_FAILURE';

export const SAVE_CRAFTS_REQUEST = 'SAVE_CRAFTS_REQUEST';
export const SAVE_CRAFTS_SUCCESS = 'SAVE_CRAFTS_SUCCESS';
export const SAVE_CRAFTS_FAILURE = 'SAVE_CRAFTS_FAILURE';

export const UPDATE_CRAFTS_REQUEST = 'UPDATE_CRAFTS_REQUEST';
export const UPDATE_CRAFTS_SUCCESS = 'UPDATE_CRAFTS_SUCCESS';
export const UPDATE_CRAFTS_FAILURE = 'UPDATE_CRAFTS_FAILURE';

export const ADD_CRAFT_REQUEST = 'ADD_CRAFT_REQUEST';
export const ADD_CRAFT_SUCCESS = 'ADD_CRAFT_SUCCESS';
export const ADD_CRAFT_FAILURE = 'ADD_CRAFT_FAILURE';

export const FETCH_CRAFT_REQUEST = 'FETCH_CRAFT_REQUEST';
export const FETCH_CRAFT_SUCCESS = 'FETCH_CRAFT_SUCCESS';
export const FETCH_CRAFT_FAILURE = 'FETCH_CRAFT_FAILURE';

export const EDIT_CRAFT_REQUEST = 'EDIT_CRAFT_REQUEST';
export const EDIT_CRAFT_SUCCESS = 'EDIT_CRAFT_SUCCESS';
export const EDIT_CRAFT_FAILURE = 'EDIT_CRAFT_FAILURE';

export const DELETE_CRAFT_REQUEST = 'DELETE_CRAFT_REQUEST';
export const DELETE_CRAFT_SUCCESS = 'DELETE_CRAFT_SUCCESS';
export const DELETE_CRAFT_FAILURE = 'DELETE_CRAFT_FAILURE';

export const SET_CURR_PROD = 'SET_CURR_PROD';

const initState = {
  allIds: [],
  byId: {},
  isLoading: false,
  total: 0,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_CRAFTS_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_CRAFT_SUCCESS:
        // we want to add the id to the allIds array if it's not in the array
        if (!draft.byId[action.craft.id]) {
          draft.allIds.push(action.craft.id);
        }
        draft.byId[action.craft.id] = action.craft;
        draft.isLoading = false;
        break;

      case FETCH_CRAFTS_SUCCESS:
        draft.byId = getObject(action.crafts, 'id');
        draft.allIds = getIds(action.crafts, 'id');
        draft.isLoading = false;

        break;

      case FETCH_CRAFT_FAILURE:
      case FETCH_CRAFTS_FAILURE:
        draft.isLoading = false;
        break;

      case ADD_CRAFT_SUCCESS:
        draft.byId[action.craft.id] = action.craft;
        draft.allIds.push(action.craft.id);
        break;

      case EDIT_CRAFT_SUCCESS:
        draft.byId[action.craft.id] = action.craft;
        break;

      case DELETE_CRAFT_SUCCESS:
        delete draft.byId[action.id];
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        break;

      default:
        return state;
    }
  });
}

export const fetchCrafts = projId => async dispatch => {
  try {
    dispatch({ type: FETCH_CRAFTS_REQUEST });
    const response = await axios.get(
      `/api/Crafts?project_id=${projId}&limit=1000`
    );

    dispatch({
      type: FETCH_CRAFTS_SUCCESS,
      crafts: response.data.craft,
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_CRAFTS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch crafts',
      },
    });
  }
};

export const fetchCraft = (projId, craftId) => async dispatch => {
  try {
    dispatch({ type: FETCH_CRAFT_REQUEST });
    const response = await axios.get(
      `/api/Crafts?id=${craftId}&project_id=${projId}`
    );

    dispatch({
      type: FETCH_CRAFT_SUCCESS,
      craft: response.data.craft[0],
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_CRAFT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch product',
      },
    });
  }
};

export const addCraft = craft => async dispatch => {
  try {
    dispatch({ type: ADD_CRAFT_REQUEST });

    const res = await axios.post('/api/Craft', [
      {
        root: 'craft',
        craft,
      },
    ]);

    return dispatch({
      type: ADD_CRAFT_SUCCESS,
      craft: res.data.craft[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_CRAFT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot add craft. Please try again',
      },
    });
  }
};

export const editCraft = craft => async dispatch => {
  try {
    dispatch({ type: EDIT_CRAFT_REQUEST });

    const res = await axios.put('/api/Craft', [
      {
        root: 'craft',
        craft,
      },
    ]);

    return dispatch({
      type: EDIT_CRAFT_SUCCESS,
      craft: res.data.craft[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Saved',
      },
    });
  } catch (error) {
    dispatch({
      type: EDIT_CRAFT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot update craft. Please try again',
      },
    });
  }
};

export const deleteCraft = craft => async dispatch => {
  try {
    dispatch({ type: DELETE_CRAFT_REQUEST });

    await axios.delete('/api/Craft', {
      data: [
        {
          root: 'craft',
          craft,
        },
      ],
    });

    return dispatch({
      type: DELETE_CRAFT_SUCCESS,
      id: craft.id,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    let message = 'Cannot delete craft. Please try again';
    if (error.response && error.response.status === 412) {
      message = 'Cannot delete craft. It is used in a product.';
    }
    dispatch({
      type: DELETE_CRAFT_FAILURE,
      globalNotification: {
        variant: 'error',
        message,
      },
    });
  }
};

export const setCurrentCraft = prod_id => ({ type: SET_CURR_PROD, prod_id });

import reduce from 'lodash/reduce';

export const getIds = (arr, id) => arr.map(item => item[id]);

export const getObject = (arr, id) =>
  reduce(
    arr,
    (result, item) => {
      result[item[id]] = item;
      return result;
    },
    {}
  );

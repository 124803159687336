import axios from 'axios';
import produce from 'immer';
import { getIds, getObject } from 'utils';

export const FETCH_MODELS_REQUEST = 'FETCH_MODELS_REQUEST';
export const FETCH_MODELS_SUCCESS = 'FETCH_MODELS_SUCCESS';
export const FETCH_MODELS_FAILURE = 'FETCH_MODELS_FAILURE';

export const FETCH_MODEL_REQUEST = 'FETCH_MODEL_REQUEST';
export const FETCH_MODEL_SUCCESS = 'FETCH_MODEL_SUCCESS';
export const FETCH_MODEL_FAILURE = 'FETCH_MODEL_FAILURE';

export const EDIT_MODEL_REQUEST = 'EDIT_MODEL_REQUEST';
export const EDIT_MODEL_SUCCESS = 'EDIT_MODEL_SUCCESS';
export const EDIT_MODEL_FAILURE = 'EDIT_MODEL_FAILURE';

export const ADD_MODEL_REQUEST = 'ADD_MODEL_REQUEST';
export const ADD_MODEL_SUCCESS = 'ADD_MODEL_SUCCESS';
export const ADD_MODEL_FAILURE = 'ADD_MODEL_FAILURE';

export const DELETE_MODEL_REQUEST = 'DELETE_MODEL_REQUEST';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';
export const DELETE_MODEL_FAILURE = 'DELETE_MODEL_FAILURE';

const initState = {
  byId: {},
  allIds: [],
  isLoading: false,
  total: 0,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_MODEL_REQUEST:
      case FETCH_MODELS_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_MODEL_SUCCESS:
        // we want to add the id to the allIds array if it's not in the array
        if (!draft.byId[action.model.id]) {
          draft.allIds.push(action.model.id);
        }
        draft.byId[action.model.id] = action.model;
        draft.isLoading = false;
        break;

      case FETCH_MODELS_SUCCESS:
        draft.byId = getObject(action.models, 'id');
        draft.allIds = getIds(action.models, 'id');
        draft.isLoading = false;
        break;

      case FETCH_MODEL_FAILURE:
      case FETCH_MODELS_FAILURE:
        draft.isLoading = false;
        break;

      case ADD_MODEL_SUCCESS:
        draft.allIds.push(action.model.id);
        draft.byId[action.model.id] = action.model;
        break;

      case EDIT_MODEL_SUCCESS:
        draft.byId[action.model.id] = action.model;
        break;

      case DELETE_MODEL_SUCCESS:
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        delete draft.byId[action.id];
        break;

      default:
        return state;
    }
  });
}

export const fetchModel = (projId, modelId) => async dispatch => {
  try {
    dispatch({ type: FETCH_MODEL_REQUEST });
    const response = await axios.get(
      `/api/Models?id=${modelId}&project_id=${projId}`
    );

    dispatch({
      type: FETCH_MODEL_SUCCESS,
      model: response.data.model[0],
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_MODEL_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch model',
      },
    });
  }
};

export const fetchModels = projId => async dispatch => {
  try {
    dispatch({ type: FETCH_MODELS_REQUEST });
    const response = await axios.get(
      `/api/Models?project_id=${projId}&limit=1000`
    );
    dispatch({
      type: FETCH_MODELS_SUCCESS,
      models: response.data.model,
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_MODELS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch models',
      },
    });
  }
};

export const addModel = model => async dispatch => {
  try {
    dispatch({ type: ADD_MODEL_REQUEST });

    const res = await axios.post('/api/Model', [
      {
        root: 'model',
        model,
      },
    ]);

    return dispatch({
      type: ADD_MODEL_SUCCESS,
      model: res.data.model[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_MODEL_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot add model. Please try again',
      },
    });
  }
};

export const editModel = model => async dispatch => {
  try {
    dispatch({ type: EDIT_MODEL_REQUEST });

    const res = await axios.put('/api/Model', [
      {
        root: 'model',
        model,
      },
    ]);

    return dispatch({
      type: EDIT_MODEL_SUCCESS,
      model: res.data.model[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Saved',
      },
    });
  } catch (error) {
    dispatch({
      type: EDIT_MODEL_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot edit model. Please try again',
      },
    });
  }
};

export const deleteModel = model => async dispatch => {
  try {
    dispatch({ type: DELETE_MODEL_REQUEST });

    await axios.delete('/api/Model', {
      data: [
        {
          root: 'model',
          model,
        },
      ],
    });

    return dispatch({
      type: DELETE_MODEL_SUCCESS,
      id: model.id,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    let message = 'Cannot delete model. Please try again';
    if (error.response && error.response.status === 412) {
      message = 'Cannot delete model. It is used in a unit.';
    }
    dispatch({
      type: DELETE_MODEL_FAILURE,
      globalNotification: {
        variant: 'error',
        message,
      },
    });
  }
};

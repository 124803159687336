import axios from 'axios';
import { getIds, getObject } from 'utils';
import produce from 'immer';
import qs from 'query-string';

export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export const SAVE_PRODUCTS_REQUEST = 'SAVE_PRODUCTS_REQUEST';
export const SAVE_PRODUCTS_SUCCESS = 'SAVE_PRODUCTS_SUCCESS';
export const SAVE_PRODUCTS_FAILURE = 'SAVE_PRODUCTS_FAILURE';

export const UPDATE_PRODUCTS_REQUEST = 'UPDATE_PRODUCTS_REQUEST';
export const UPDATE_PRODUCTS_SUCCESS = 'UPDATE_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_FAILURE = 'UPDATE_PRODUCTS_FAILURE';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_FAILURE = 'EDIT_PRODUCT_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const SET_CURR_PROD = 'SET_CURR_PROD';

const initState = {
  allIds: [],
  byId: {},
  isLoading: false,
  total: 0,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_PRODUCTS_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_PRODUCT_SUCCESS:
        // we want to add the id to the allIds array if it's not in the array
        if (!draft.byId[action.product.id]) {
          draft.allIds.push(action.product.id);
        }
        draft.byId[action.product.id] = action.product;
        draft.isLoading = false;
        break;

      case FETCH_PRODUCTS_SUCCESS:
        draft.byId = getObject(action.products, 'id');
        draft.allIds = getIds(action.products, 'id');
        draft.isLoading = false;
        break;

      case FETCH_PRODUCT_FAILURE:
      case FETCH_PRODUCTS_FAILURE:
        draft.isLoading = false;
        break;

      case ADD_PRODUCT_SUCCESS:
        draft.byId[action.product.id] = action.product;
        draft.allIds.push(action.product.id);
        break;

      case EDIT_PRODUCT_SUCCESS:
        draft.byId[action.product.id] = action.product;
        break;

      case DELETE_PRODUCT_SUCCESS:
        delete draft.byId[action.id];
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        break;

      default:
        return state;
    }
  });
}

export const fetchProduct = (projId, prodId) => async dispatch => {
  try {
    dispatch({ type: FETCH_PRODUCT_REQUEST });
    const response = await axios.get(
      `/api/Products?id=${prodId}&project_id=${projId}`
    );

    dispatch({
      type: FETCH_PRODUCT_SUCCESS,
      product: response.data.product[0],
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_PRODUCT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch product',
      },
    });
  }
};

export const fetchProducts = (
  projId,
  query = { limit: 1000 }
) => async dispatch => {
  try {
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
    const queryString = qs.stringify(query);
    const response = await axios.get(
      `/api/Products?project_id=${projId}&${queryString}`
    );
    dispatch({
      type: FETCH_PRODUCTS_SUCCESS,
      products: response.data.product,
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_PRODUCTS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch products',
      },
    });
  }
};

export const addProduct = product => async dispatch => {
  try {
    dispatch({ type: ADD_PRODUCT_REQUEST });

    const res = await axios.post('/api/Product', [
      {
        root: 'product',
        product,
      },
    ]);

    return dispatch({
      type: ADD_PRODUCT_SUCCESS,
      product: res.data.product[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_PRODUCT_FAILURE,
      globalNotification: {
        variant: 'error',
        message:
          error.response.data.message || 'Cannot add product. Please try again',
      },
    });
  }
};

export const editProduct = product => async dispatch => {
  try {
    dispatch({ type: EDIT_PRODUCT_REQUEST });

    const res = await axios.put('/api/Product', [
      {
        root: 'product',
        product,
      },
    ]);
    return dispatch({
      type: EDIT_PRODUCT_SUCCESS,
      product: res.data.product[0].fields,
    });
  } catch (error) {
    dispatch({
      type: EDIT_PRODUCT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot edit product. Please try again',
      },
    });
  }
};

export const deleteProduct = product => async dispatch => {
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    await axios.delete('/api/Product', {
      data: [
        {
          root: 'product',
          product,
        },
      ],
    });

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      id: product.id,
    });
  } catch (error) {
    let message = 'Cannot delete product. Please try again';
    if (error.response && error.response.status === 412) {
      message = 'Cannot delete product. It is used in a model.';
    }
    dispatch({
      type: DELETE_PRODUCT_FAILURE,
      globalNotification: {
        variant: 'error',
        message,
      },
    });
  }
};

export const setCurrentProduct = prod_id => ({ type: SET_CURR_PROD, prod_id });

import axios from 'axios';
import produce from 'immer';

export const FETCH_SESSION_REQUEST = 'FETCH_SESSION_REQUEST';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAILURE = 'FETCH_SESSION_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

const initState = {
  user: null,
  isAuthenticated: false,
  fetched: false,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case LOGIN_REQUEST:
        break;

      case LOGIN_SUCCESS:
        draft.user = action.user;
        draft.isAuthenticated = true;
        break;

      case LOGIN_FAILURE:
        draft.isAuthenticated = false;
        break;

      case FETCH_SESSION_SUCCESS:
        draft.user = action.user;
        draft.isAuthenticated = true;
        draft.fetched = true;
        break;

      case FETCH_SESSION_FAILURE:
        draft.user = {};
        draft.isAuthenticated = false;
        draft.fetched = true;
        break;

      case LOGOUT_SUCCESS:
        draft.user = null;
        draft.isAuthenticated = false;
        break;

      default:
        return state;
    }
  });
}

/**
 * Local login
 * TODO: Retry if timeout
 * @param {string} userId
 * @param {string} password
 * @param {function} callback
 */
export const handleLocalLogin = (userid, password) => async dispatch => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const res = await axios.post('/api/Auth', {
      userid,
      password,
    });

    return dispatch({ type: LOGIN_SUCCESS, user: res.data.user });
  } catch (err) {
    let message = 'Something went wrong!';
    if (err.response && err.response.status) {
      const { status } = err.response;
      if (status === 423) {
        message = 'Retry limit exceeded. Please contact the administrator.';
      } else if (status === 401 || status === 403) {
        message = 'Invalid username or password.';
      }
    }
    dispatch({
      type: LOGIN_FAILURE,
      globalNotification: {
        variant: 'error',
        message,
      },
    });
  }
};

export const handleLogout = () => async dispatch => {
  try {
    dispatch({ type: LOGOUT_REQUEST });

    await axios.delete('/api/Auth');

    dispatch({ type: LOGOUT_SUCCESS });
  } catch {
    dispatch({
      type: LOGOUT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Failed to logout. Please try again.',
      },
    });
  }
};

export const fetchSession = () => async dispatch => {
  try {
    dispatch({ type: FETCH_SESSION_REQUEST });

    const res = await axios.get('/api/User');
    return dispatch({ type: FETCH_SESSION_SUCCESS, user: res.data.user });
  } catch {
    dispatch({ type: FETCH_SESSION_FAILURE });
  }
};

export const handleOAuth = () => async dispatch => {
  try {
    const res = await axios.get('/api/login');
    const uri = res.data.uri;
    window.location = uri;
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Something went wrong. Please try again',
      },
    });
  }
};

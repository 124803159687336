import _ from 'lodash';
import { createSelector } from 'reselect';

export const FETCH_PMODELS_REQUEST = 'FETCH_PMODELS_REQUEST';
export const FETCH_PMODELS_SUCCESS = 'FETCH_PMODELS_SUCCESS';
export const FETCH_PMODELS_FAIL = 'FETCH_PMODELS_FAIL';

export const SAVE_PMODELS_REQUEST = 'SAVE_PMODELS_REQUEST';
export const SAVE_PMODELS_SUCCESS = 'SAVE_PMODELS_SUCCESS';
export const SAVE_PMODELS_FAIL = 'SAVE_PMODELS_FAIL';

export const UPDATE_PMODELS_REQUEST = 'UPDATE_PMODELS_REQUEST';
export const UPDATE_PMODELS_SUCCESS = 'UPDATE_PMODELS_SUCCESS';
export const UPDATE_PMODELS_FAIL = 'UPDATE_PMODELS_FAIL';

export const ADD_PMODELS_REQUEST = 'ADD_PMODELS_REQUEST';
export const ADD_PMODELS_SUCCESS = 'ADD_PMODELS_SUCCESS';
export const ADD_PMODELS_FAIL = 'ADD_PMODELS_FAIL';

export const ADD_PMODEL_REQUEST = 'ADD_PMODEL_REQUEST';
export const ADD_PMODEL_SUCCESS = 'ADD_PMODEL_SUCCESS';
export const ADD_PMODEL_FAIL = 'ADD_PMODEL_FAIL';

export const DELETE_PMODEL_REQUEST = 'DELETE_PMODEL_REQUEST';
export const DELETE_PMODEL_SUCCESS = 'DELETE_PMODEL_SUCCESS';
export const DELETE_PMODEL_FAIL = 'DELETE_PMODEL_FAIL';

const initState = {
  productModels: [],
  isLoading: true,
  total: 0,
  error: false,
};

export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_PMODELS_REQUEST:
    case ADD_PMODEL_REQUEST:
    case DELETE_PMODEL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case FETCH_PMODELS_SUCCESS:
      return {
        ...state,
        productModels: action.productModels,
        isLoading: false,
        error: false,
      };

    case ADD_PMODEL_SUCCESS:
    case DELETE_PMODEL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    }
    case ADD_PMODELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    case SAVE_PMODELS_REQUEST:
      return {
        ...state,
        isLoading: false,
      };

    case SAVE_PMODELS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case ADD_PMODEL_FAIL:
    case DELETE_PMODEL_FAIL:
    case SAVE_PMODELS_FAIL:
    case FETCH_PMODELS_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    }

    case UPDATE_PMODELS_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}
/*eslint-disable no-unused-vars */
export const fetchProductModels = (
  projId,
  prodId,
  pageSize,
  page,
  sortField = [],
  filterField = []
) => async (dispatch, getState) => {};

export const fetchMandCreatePM = (projId, prodId) => async (
  dispatch,
  getState
) => {};

export const addProductModel = (
  productModel,
  projId,
  successCB
) => async dispatch => {};

export const saveProductModels = (units, projId) => async dispatch => {};

export const deleteProductModel = (
  productModel,
  projId,
  pageSize,
  page,
  sorted,
  filtered
) => async dispatch => {};

/*eslint-enable no-unused-vars */

const byIdSelector = state => state.byId;
const idsOrderSelector = state => state.productModels;

export const getProductModelsOrdered = createSelector(
  byIdSelector,
  idsOrderSelector,
  (productModels, order) => {
    const productModelsById = _.values(productModels).map(
      productModel => productModel.fields
    );
    return productModelsById.slice().sort((a, b) => {
      return order.indexOf(a.pk.toString()) - order.indexOf(b.pk.toString());
    });
  }
);

import axios from 'axios';
import { getIds, getObject } from 'utils';
import produce from 'immer';

export const FETCH_ROOM_REQUEST = 'FETCH_ROOM_REQUEST';
export const FETCH_ROOM_SUCCESS = 'FETCH_ROOM_SUCCESS';
export const FETCH_ROOM_FAILURE = 'FETCH_ROOM_FAILURE';

export const FETCH_ROOMS_REQUEST = 'FETCH_ROOMS_REQUEST';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
export const FETCH_ROOMS_FAILURE = 'FETCH_ROOMS_FAILURE';

export const ADD_ROOM_REQUEST = 'ADD_ROOM_REQUEST';
export const ADD_ROOM_SUCCESS = 'ADD_ROOM_SUCCESS';
export const ADD_ROOM_FAILURE = 'ADD_ROOM_FAILURE';

export const EDIT_ROOM_REQUEST = 'EDIT_ROOM_REQUEST';
export const EDIT_ROOM_SUCCESS = 'EDIT_ROOM_SUCCESS';
export const EDIT_ROOM_FAILURE = 'EDIT_ROOM_FAILURE';

export const DELETE_ROOM_REQUEST = 'DELETE_ROOM_REQUEST';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const DELETE_ROOM_FAILURE = 'DELETE_ROOM_FAILURE';

const initState = {
  allIds: [],
  byId: {},
  isLoading: false,
  total: 0,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_ROOM_REQUEST:
      case FETCH_ROOMS_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_ROOM_SUCCESS:
        // we want to add the id to the allIds array if it's not in the array
        if (!draft.byId[action.room.id]) {
          draft.allIds.push(action.room.id);
        }
        draft.byId[action.room.id] = action.room;
        draft.isLoading = false;
        break;

      case FETCH_ROOMS_SUCCESS:
        draft.byId = getObject(action.rooms, 'id');
        draft.allIds = getIds(action.rooms, 'id');
        draft.isLoading = false;
        break;

      case FETCH_ROOM_FAILURE:
      case FETCH_ROOMS_FAILURE:
        draft.isLoading = false;
        break;

      case ADD_ROOM_SUCCESS:
        draft.byId[action.room.id] = action.room;
        draft.allIds.push(action.room.id);
        break;

      case EDIT_ROOM_SUCCESS:
        draft.byId[action.room.id] = action.room;
        break;

      case DELETE_ROOM_SUCCESS:
        delete draft.byId[action.id];
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        break;

      default:
        return state;
    }
  });
}

export const fetchRoom = (projId, roomId) => async dispatch => {
  try {
    dispatch({ type: FETCH_ROOM_REQUEST });

    const response = await axios.get(
      `/api/Rooms?project_id=${projId}&id=${roomId}`
    );

    dispatch({
      type: FETCH_ROOM_SUCCESS,
      room: response.data.room[0],
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ROOM_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch room',
      },
    });
  }
};

export const fetchRooms = projId => async dispatch => {
  try {
    dispatch({ type: FETCH_ROOMS_REQUEST });

    const response = await axios.get(
      `/api/Rooms?project_id=${projId}&limit=1000`
    );

    dispatch({
      type: FETCH_ROOMS_SUCCESS,
      rooms: response.data.room,
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_ROOMS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch rooms',
      },
    });
  }
};

export const addRoom = room => async dispatch => {
  try {
    dispatch({ type: ADD_ROOM_REQUEST });

    delete room.id;
    room.short = room.short.toUpperCase();

    const res = await axios.post('/api/Room', [
      {
        root: 'room',
        room: room,
      },
    ]);

    return dispatch({
      type: ADD_ROOM_SUCCESS,
      room: res.data.room[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_ROOM_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot add room. Please try again',
      },
    });
  }
};

export const editRoom = room => async dispatch => {
  try {
    dispatch({ type: EDIT_ROOM_REQUEST });

    room.short = room.short.toUpperCase();

    const res = await axios.put('/api/Room', [
      {
        root: 'room',
        room: room,
      },
    ]);

    return dispatch({
      type: EDIT_ROOM_SUCCESS,
      room: res.data.room[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Saved',
      },
    });
  } catch (error) {
    dispatch({
      type: EDIT_ROOM_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot update room. Please try again',
      },
    });
  }
};

export const deleteRoom = room => async dispatch => {
  try {
    dispatch({ type: DELETE_ROOM_REQUEST });

    await axios.delete('/api/Room', {
      data: [
        {
          root: 'room',
          room,
        },
      ],
    });

    return dispatch({
      type: DELETE_ROOM_SUCCESS,
      id: room.id,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    let message = 'Cannot delete room. Please try again';
    if (error.response && error.response.status === 412) {
      message = 'Cannot delete room. It is used in a model.';
    }
    dispatch({
      type: DELETE_ROOM_FAILURE,
      globalNotification: {
        variant: 'error',
        message,
      },
    });
  }
};

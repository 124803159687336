import axios from 'axios';
import { getIds, getObject } from 'utils';
import produce from 'immer';

export const FETCH_UNIT_REQUEST = 'FETCH_UNIT_REQUEST';
export const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS';
export const FETCH_UNIT_FAILURE = 'FETCH_UNIT_FAILURE';

export const FETCH_UNITS_REQUEST = 'FETCH_UNITS_REQUEST';
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS';
export const FETCH_UNITS_FAILURE = 'FETCH_UNITS_FAILURE';

export const ADD_UNIT_REQUEST = 'ADD_UNIT_REQUEST';
export const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS';
export const ADD_UNIT_FAILURE = 'ADD_UNIT_FAILURE';

export const EDIT_UNIT_REQUEST = 'EDIT_UNIT_REQUEST';
export const EDIT_UNIT_SUCCESS = 'EDIT_UNIT_SUCCESS';
export const EDIT_UNIT_FAILURE = 'EDIT_UNIT_FAILURE';

export const DELETE_UNIT_REQUEST = 'DELETE_UNIT_REQUEST';
export const DELETE_UNIT_SUCCESS = 'DELETE_UNIT_SUCCESS';
export const DELETE_UNIT_FAILURE = 'DELETE_UNIT_FAILURE';

export const CLEAR_UNITS = 'CLEAR_UNITS';

const initState = {
  allIds: [],
  byId: {},
  isLoading: false,
  total: 0,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_UNIT_REQUEST:
      case FETCH_UNITS_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_UNIT_SUCCESS:
        // we want to add the id to the allIds array if it's not in the array
        if (!draft.byId[action.unit.id]) {
          draft.allIds.push(action.unit.id);
        }
        draft.byId[action.unit.id] = action.unit;
        draft.isLoading = false;
        break;

      case FETCH_UNITS_SUCCESS:
        draft.byId = getObject(action.units, 'id');
        draft.allIds = getIds(action.units, 'id');
        draft.isLoading = false;
        break;

      case FETCH_UNIT_FAILURE:
      case FETCH_UNITS_FAILURE:
        draft.isLoading = false;
        break;

      case ADD_UNIT_SUCCESS:
        draft.byId[action.unit.id] = action.unit;
        draft.allIds.push(action.unit.id);
        draft.isLoading = false;
        break;

      case EDIT_UNIT_SUCCESS:
        draft.byId[action.unit.id] = action.unit;
        break;

      case DELETE_UNIT_SUCCESS:
        delete draft.byId[action.id];
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        break;

      case CLEAR_UNITS:
        draft.allIds = [];
        draft.byId = {};
        draft.total = 0;
        break;

      default:
        return state;
    }
  });
}

export const fetchUnit = (projId, unitId) => async dispatch => {
  try {
    dispatch({ type: FETCH_UNIT_REQUEST });

    const response = await axios.get(
      `/api/Units?project_id=${projId}&id=${unitId}`
    );

    dispatch({
      type: FETCH_UNIT_SUCCESS,
      unit: response.data.unit[0],
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_UNIT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch unit',
      },
    });
  }
};

export const fetchUnits = (projId, params = {}) => async dispatch => {
  try {
    dispatch({ type: FETCH_UNITS_REQUEST });
    let url = `/api/Units?project_id=${projId}&limit=1000`;
    if (params.unitstatus) {
      url = `${url}&unitstatus=${params.unitstatus}`;
    }

    const response = await axios.get(url);

    dispatch({
      type: FETCH_UNITS_SUCCESS,
      units: response.data.unit,
      total: response.data.total,
    });
  } catch (err) {
    dispatch({
      type: FETCH_UNITS_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not fetch units',
      },
    });
  }
};

export const addUnit = unit => async dispatch => {
  try {
    dispatch({ type: ADD_UNIT_REQUEST });

    const res = await axios.post('/api/Unit', [
      {
        root: 'unit',
        unit: unit,
      },
    ]);

    return dispatch({
      type: ADD_UNIT_SUCCESS,
      unit: res.data.unit[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_UNIT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot add unit. Please try again',
      },
    });
  }
};

export const editUnit = unit => async dispatch => {
  try {
    dispatch({ type: EDIT_UNIT_REQUEST });

    const res = await axios.put('/api/Unit', [
      {
        root: 'unit',
        unit: {
          id: unit.id,
          unitno: unit.unitno,
          municipal: unit.munipal,
          baseprice: unit.baseprice,
          unitstatus: unit.unitstatus,
          project: unit.project,
          note: unit.note,
          model: unit.model,
        },
      },
    ]);

    return dispatch({
      type: EDIT_UNIT_SUCCESS,
      unit: res.data.unit[0].fields,
      globalNotification: {
        variant: 'success',
        message: 'Saved',
      },
    });
  } catch (error) {
    dispatch({
      type: EDIT_UNIT_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot update unit. Please try again',
      },
    });
  }
};

export const deleteUnit = unit => async dispatch => {
  try {
    dispatch({ type: DELETE_UNIT_REQUEST });

    await axios.delete('/api/Unit', {
      data: [
        {
          root: 'unit',
          unit,
        },
      ],
    });

    return dispatch({
      type: DELETE_UNIT_SUCCESS,
      id: unit.id,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    let message = 'Cannot delete unit. Please try again';
    if (error.response && error.response.status === 412) {
      message = 'Cannot delete unit. It is used in a sale.';
    }
    dispatch({
      type: DELETE_UNIT_FAILURE,
      globalNotification: {
        variant: 'error',
        message,
      },
    });
  }
};

export const clearUnits = () => ({
  type: CLEAR_UNITS,
});

import axios from 'axios';
import produce from 'immer';
import { getIds, getObject } from 'utils';
import editUnit from 'state/ducks/residential/units';
import qs from 'query-string';
export const ADD_SALE_REQUEST = 'ADD_SALE_REQUEST';
export const ADD_SALE_SUCCESS = 'ADD_SALE_SUCCESS';
export const ADD_SALE_FAILURE = 'ADD_SALE_FAILURE';

export const UPDATE_SALE_REQUEST = 'UPDATE_SALE_REQUEST';
export const UPDATE_SALE_SUCCESS = 'UPDATE_SALE_SUCCESS';
export const UPDATE_SALE_FAILURE = 'UPDATE_SALE_FAILURE';

export const DELETE_SALE_REQUEST = 'DELETE_SALE_REQUEST';
export const DELETE_SALE_SUCCESS = 'DELETE_SALE_SUCCESS';
export const DELETE_SALE_FAILURE = 'DELETE_SALE_FAILURE';

export const FETCH_SALES_REQUEST = 'FETCH_SALES_REQUEST';
export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS';
export const FETCH_SALES_FAILURE = 'FETCH_SALES_FAILURE';

export const SET_ACTIVE_SALE = 'SET_ACTIVE_SALE';
const initState = {
  byId: {},
  allIds: [],
  total: 0,
  limit: 0,
  isLoading: false,
};

export default function(state = initState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case FETCH_SALES_REQUEST:
        draft.isLoading = true;
        break;

      case FETCH_SALES_SUCCESS:
        draft.isLoading = false;
        draft.byId = getObject(action.sales, 'id');
        draft.allIds = getIds(action.sales, 'id');
        draft.total = action.total;
        break;

      case FETCH_SALES_FAILURE:
        draft.isLoading = false;
        break;

      case UPDATE_SALE_SUCCESS:
        draft.byId[action.sale.id] = action.sale;
        break;

      case ADD_SALE_SUCCESS:
        draft.byId[action.sale.id] = action.sale;
        draft.allIds.push(action.sale.id);
        break;

      case DELETE_SALE_SUCCESS:
        delete draft.byId[action.id];
        draft.allIds = draft.allIds.filter(id => id !== action.id);
        break;

      default:
        return state;
    }
  });
}

/**
 * Checks if sales is already being fetced
 * @param {sales} param0
 * @returns {boolean} - true if sales should be fetched
 */
const shouldFetchSales = ({ rd: { sales } }) => {
  return !sales.isLoading;
};

/**
 *
 * @param {object} o - the parameters for fetching sales
 * @param {number} o.projId - the project id
 * @param {number} o.pageSize - number of rows per page
 * @param {number} o.page - the page to fetch
 * @param {array} [o.sortField=[]] - the sort by filer
 * @param {array} [o.filterField=[]] - the search filter
 */
export const fetchSales = (projId, queryParams = { limit: 5 }) => async (
  dispatch,
  getState
) => {
  // don't fetch if already fetching
  if (shouldFetchSales(getState())) {
    try {
      dispatch({ type: FETCH_SALES_REQUEST });
      const queryString = qs.stringify(queryParams);
      const response = await axios.get(
        `/api/v2/sales?project_id=${projId}&${queryString}`
      );

      const { data } = response;

      dispatch({
        type: FETCH_SALES_SUCCESS,
        sales: data.salestats,
        total: data.rows,
      });
    } catch (err) {
      dispatch({
        type: FETCH_SALES_FAILURE,
        globalNotification: {
          variant: 'error',
          message: 'Could not fetch sales. Please try again',
        },
      });
    }
  }

  return;
};

export const addSale = sale => async dispatch => {
  try {
    dispatch({ type: ADD_SALE_REQUEST });

    const res = await axios.post('/api/v2/sale', {
      ...sale,
    });

    return dispatch({
      type: ADD_SALE_SUCCESS,
      sale: res.data.sale,
      globalNotification: {
        variant: 'success',
        message: 'Added',
      },
    });
  } catch (error) {
    dispatch({
      type: ADD_SALE_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Cannot add sale. Please try again',
      },
    });
  }
};

export const deleteSale = sale => async dispatch => {
  try {
    dispatch({ type: DELETE_SALE_REQUEST });

    if (sale.units.sales.length === 1) {
      // If this sale is the only sale for the unit, set
      // the unit status to 'available'
      sale.unit.status = 'Available';
      editUnit(sale.unit);
    }

    await axios.delete(`/api/v2/sale/${sale.id}`);

    return dispatch({
      type: DELETE_SALE_SUCCESS,
      id: sale.id,
      globalNotification: {
        variant: 'success',
        message: 'Deleted',
      },
    });
  } catch (error) {
    let message = 'Cannot delete sale. Please try again';
    dispatch({
      type: DELETE_SALE_FAILURE,
      globalNotification: {
        variant: 'error',
        message,
      },
    });
  }
};

/**
 * Save a sale
 * @param {object} sale - the sale data
 */
export const updateSale = sale => async dispatch => {
  try {
    dispatch({ type: UPDATE_SALE_REQUEST });
    const { id, ...rest } = sale;
    const res = await axios.put(`/api/v2/sale/${id}`, { ...rest });

    return dispatch({
      type: UPDATE_SALE_SUCCESS,
      sale: res.data.sale,
      globalNotification: {
        variant: 'success',
        message: 'Saved!',
      },
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SALE_FAILURE,
      globalNotification: {
        variant: 'error',
        message: 'Could not save changes. Please try again',
      },
    });
  }
};

import axios from 'axios';
import qs from 'qs';

export const FETCH_MODELELEVS_REQUEST = 'FETCH_MODELELEVS_REQUEST';
export const FETCH_MODELELEVS_SUCCESS = 'FETCH_MODELELEVS_SUCCESS';
export const FETCH_MODELELEVS_FAIL = 'FETCH_MODELELEVS_FAIL';

export const FETCH_MODELRMS_REQUEST = 'FETCH_MODELRMS_REQUEST';
export const FETCH_MODELRMS_SUCCESS = 'FETCH_MODELRMS_SUCCESS';
export const FETCH_MODELRMS_FAIL = 'FETCH_MODELRMS_FAIL';

export const SAVE_MODELRMS_REQUEST = 'SAVE_MODELRMS_REQUEST';
export const SAVE_MODELRMS_SUCCESS = 'SAVE_MODELRMS_SUCCESS';
export const SAVE_MODELRMS_FAIL = 'SAVE_MODELRMS_FAIL';

export const UPDATE_MODELRMS_REQUEST = 'UPDATE_MODELRMS_REQUEST';
export const UPDATE_MODELRMS_SUCCESS = 'UPDATE_MODELRMS_SUCCESS';
export const UPDATE_MODELRMS_FAIL = 'UPDATE_MODELRMS_FAIL';

export const ADD_MODELRM_REQUEST = 'ADD_MODELRM_REQUEST';
export const ADD_MODELRM_SUCCESS = 'ADD_MODELRM_SUCCESS';
export const ADD_MODELRM_FAIL = 'ADD_MODELRM_FAIL';

export const DELETE_MODELRM_REQUEST = 'DELETE_MODELRM_REQUEST';
export const DELETE_MODELRM_SUCCESS = 'DELETE_MODELRM_SUCCESS';
export const DELETE_MODELRM_FAIL = 'DELETE_MODELRM_FAIL';

const initState = {
  modelRooms: [],
  isLoading: true,
  total: 0,
};
export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_MODELELEVS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_MODELELEVS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_MODELRMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        modelRooms: action.modelRooms,
        total: action.total,
      };

    case FETCH_MODELRMS_REQUEST:
    case ADD_MODELRM_REQUEST:
    case DELETE_MODELRM_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ADD_MODELRM_SUCCESS:
    case DELETE_MODELRM_SUCCESS: {
      return {
        ...state,
      };
    }

    case SAVE_MODELRMS_REQUEST:
      return {
        ...state,
      };

    case SAVE_MODELRMS_SUCCESS:
      return {
        ...state,
      };

    case FETCH_MODELELEVS_FAIL:
    case ADD_MODELRM_FAIL:
    case DELETE_MODELRM_FAIL:
    case SAVE_MODELRMS_FAIL:
    case FETCH_MODELRMS_FAIL: {
      return {
        ...state,
      };
    }

    case UPDATE_MODELRMS_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}

const fetchData = async (
  url,
  pageSize,
  page,
  sortField = [],
  //eslint-disable-next-line
  filterField = []
) => {
  let sort = null,
    direction = null;

  if (sortField.length) {
    const temp = sortField[0].id.split('.');
    sort = temp[temp.length - 1];
    direction = sortField[0].desc ? 'desc' : 'asc';
  }

  const response = await axios.get(url, {
    params: {
      pageSize,
      sort,
      page,
      direction,
    },
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return response;
};

export const fetchModelElev = (
  projId,
  pageSize,
  page,
  sortField = [],
  filterField = []
) => async dispatch => {
  try {
    dispatch({ type: FETCH_MODELELEVS_REQUEST });

    const response = await fetchData(
      `/api/project/${projId}/modelelev`,
      pageSize,
      page,
      sortField,
      filterField
    );
    if (response.data.success) {
      dispatch({ type: FETCH_MODELELEVS_SUCCESS, payload: response.data });
      return;
    }
    dispatch({
      type: FETCH_MODELELEVS_FAIL,
      message: 'Unable to fetch model rooms.',
    });
  } catch (err) {
    dispatch({
      type: FETCH_MODELELEVS_FAIL,
      message: 'Unable to fetch model rooms.',
    });
  }
};

export const fetchModelRms = (
  { model, elev },
  pageSize,
  page,
  sortField = []
) => async dispatch => {
  try {
    dispatch({ type: FETCH_MODELRMS_REQUEST });
    let sort = null,
      direction = null;
    if (sortField.length) {
      const temp = sortField[0].id.split('.');
      sort = temp[temp.length - 1];
      direction = sortField[0].desc ? 'desc' : 'asc';
    }
    const url = '/api/ModelRooms';

    const response = await axios.get(url, {
      params: {
        pageSize,
        sort,
        page,
        direction,
        model,
        elev,
      },
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });

    if (response.data.success) {
      dispatch({
        type: FETCH_MODELRMS_SUCCESS,
        payload: response.data.modelroom,
      });
      return;
    }
    dispatch({
      type: FETCH_MODELRMS_FAIL,
      message: 'Unable to fetch model rooms.',
    });
  } catch (err) {
    dispatch({
      type: FETCH_MODELRMS_FAIL,
      message: 'Unable to fetch model rooms.',
    });
  }
};

/*eslint-disable no-unused-vars */
export const addModelRms = (modelRm, projId, successCB) => async dispatch => {};

export const deleteModelRm = (
  modelRm,
  projId,
  pageSize,
  page,
  sorted,
  filtered
) => async dispatch => {};

export const saveModelRms = (modelrms, projId) => async dispatch => {};
/* eslint-enable no-unused-vars */

import axios from 'axios';

export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAIL = 'FETCH_SETTINGS_FAIL';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_SETTINGS_SUCCESS:
      return {
        ...action.settings,
        isLoading: false,
      };

    case FETCH_SETTINGS_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}

export const fetchSettings = () => async dispatch => {
  try {
    dispatch({ type: FETCH_SETTINGS_REQUEST });
    const response = await axios.get('/api/settings');
    dispatch({ type: FETCH_SETTINGS_SUCCESS, settings: response.data });
  } catch (err) {
    dispatch({ type: FETCH_SETTINGS_FAIL });
  }
};

import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';
import { createSelector } from 'reselect';

export const FETCH_PSTYLES_REQUEST = 'FETCH_PSTYLES_REQUEST';
export const FETCH_PSTYLES_SUCCESS = 'FETCH_PSTYLES_SUCCESS';
export const FETCH_PSTYLES_FAIL = 'FETCH_PSTYLES_FAIL';

export const SAVE_PSTYLES_REQUEST = 'SAVE_PSTYLES_REQUEST';
export const SAVE_PSTYLES_SUCCESS = 'SAVE_PSTYLES_SUCCESS';
export const SAVE_PSTYLES_FAIL = 'SAVE_PSTYLES_FAIL';

export const UPDATE_PSTYLES_REQUEST = 'UPDATE_PSTYLES_REQUEST';
export const UPDATE_PSTYLES_SUCCESS = 'UPDATE_PSTYLES_SUCCESS';
export const UPDATE_PSTYLES_FAIL = 'UPDATE_PSTYLES_FAIL';

export const ADD_PSTYLES_REQUEST = 'ADD_PSTYLES_REQUEST';
export const ADD_PSTYLES_SUCCESS = 'ADD_PSTYLES_SUCCESS';
export const ADD_PSTYLES_FAIL = 'ADD_PSTYLES_FAIL';

export const ADD_PSTYLE_REQUEST = 'ADD_PSTYLE_REQUEST';
export const ADD_PSTYLE_SUCCESS = 'ADD_PSTYLE_SUCCESS';
export const ADD_PSTYLE_FAIL = 'ADD_PSTYLE_FAIL';

export const DELETE_PSTYLE_REQUEST = 'DELETE_PSTYLE_REQUEST';
export const DELETE_PSTYLE_SUCCESS = 'DELETE_PSTYLE_SUCCESS';
export const DELETE_PSTYLE_FAIL = 'DELETE_PSTYLE_FAIL';

const initState = {
  productStyles: [],
  isLoading: true,
  total: 0,
  error: false,
};

export default function(state = initState, action) {
  switch (action.type) {
    case FETCH_PSTYLES_REQUEST:
    case ADD_PSTYLE_REQUEST:
    case DELETE_PSTYLE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case FETCH_PSTYLES_SUCCESS:
      return {
        ...state,
        productStyle: action.productStyles,
        total: action.total,
        isLoading: false,
        error: false,
      };

    case ADD_PSTYLE_SUCCESS:
    case DELETE_PSTYLE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    }
    case ADD_PSTYLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        productStyle: action.productStyles,
      };
    case SAVE_PSTYLES_REQUEST:
      return {
        ...state,
        isLoading: false,
      };

    case SAVE_PSTYLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case ADD_PSTYLE_FAIL:
    case DELETE_PSTYLE_FAIL:
    case SAVE_PSTYLES_FAIL:
    case FETCH_PSTYLES_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    }

    case UPDATE_PSTYLES_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}

const fetchData = async (
  url,
  pageSize,
  page,
  sortField = [],
  //eslint-disable-next-line
  filterField = []
) => {
  let sort = null,
    direction = null;

  if (sortField.length) {
    const temp = sortField[0].id.split('.');
    sort = temp[temp.length - 1];
    direction = sortField[0].desc ? 'desc' : 'asc';
  }

  const response = await axios.get(url, {
    params: {
      pageSize,
      sort,
      page,
      direction,
    },
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return response;
};
export const fetchProdStyles = (
  projId,
  prodId,
  pageSize = 1000,
  page = 0,
  sortField = [],
  filterField = []
) => async dispatch => {
  try {
    dispatch({ type: FETCH_PSTYLES_REQUEST });

    const response = await fetchData(
      `/api/project/${projId}/productStyles/${prodId}`,
      pageSize,
      page,
      sortField,
      filterField
    );
    if (response.data.success) {
      dispatch({ type: FETCH_PSTYLES_SUCCESS, payload: response.data });
      return;
    }
    dispatch({ type: FETCH_PSTYLES_FAIL, message: 'Unable to fetch rooms.' });
  } catch (err) {
    dispatch({ type: FETCH_PSTYLES_FAIL, message: 'Unable to fetch rooms.' });
  }
};

/*eslint-disable no-unused-vars */
export const addProdStyle = (
  productStyle,
  projId,
  successCB
) => async dispatch => {};

export const saveProdStyles = (units, projId) => async dispatch => {};

export const deleteProdStyle = (style, projId) => async dispatch => {};
/*eslint-enable no-unused-vars */

const byIdSelector = state => state.byId;
const idsOrderSelector = state => state.allIds;

export const getProdStylesOrdered = createSelector(
  byIdSelector,
  idsOrderSelector,
  (productStyles, order) => {
    const productStylesById = _.values(productStyles).map(
      productStyle => productStyle.fields
    );
    return productStylesById.slice().sort((a, b) => {
      return order.indexOf(a.pk.toString()) - order.indexOf(b.pk.toString());
    });
  }
);

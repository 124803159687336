import _ from 'lodash';
import axios from 'axios';
import qs from 'qs';
import { createSelector } from 'reselect';

export const FETCH_EXIST_PROOMS_REQUEST = 'FETCH_EXIST_PROOMS_REQUEST';
export const FETCH_EXIST_PROOMS_SUCCESS = 'FETCH_EXIST_PROOMS_SUCCESS';
export const FETCH_EXIST_PROOMS_FAIL = 'FETCH_EXIST_PROOMS_FAIL';

export const SAVE_PROOMS_REQUEST = 'SAVE_PROOMS_REQUEST';
export const SAVE_PROOMS_SUCCESS = 'SAVE_PROOMS_SUCCESS';
export const SAVE_PROOMS_FAIL = 'SAVE_PROOMS_FAIL';

export const UPDATE_PROOMS_REQUEST = 'UPDATE_PROOMS_REQUEST';
export const UPDATE_PROOMS_SUCCESS = 'UPDATE_PROOMS_SUCCESS';
export const UPDATE_PROOMS_FAIL = 'UPDATE_PROOMS_FAIL';

export const ADD_PROOMS_REQUEST = 'ADD_PROOMS_REQUEST';
export const ADD_PROOMS_SUCCESS = 'ADD_PROOMS_SUCCESS';
export const ADD_PROOMS_FAIL = 'ADD_PROOMS_FAIL';

export const ADD_PROOM_REQUEST = 'ADD_PROOM_REQUEST';
export const ADD_PROOM_SUCCESS = 'ADD_PROOM_SUCCESS';
export const ADD_PROOM_FAIL = 'ADD_PROOM_FAIL';

export const DELETE_PROOM_REQUEST = 'DELETE_PROOM_REQUEST';
export const DELETE_PROOM_SUCCESS = 'DELETE_PROOM_SUCCESS';
export const DELETE_PROOM_FAIL = 'DELETE_PROOM_FAIL';

export const CREATE_PROOMS_REQUEST = 'CREATE_PROOMS_REQUEST';
export const CREATE_PROOMS_SUCCESS = 'CREATE_PROOMS_SUCCESS';

const initState = {
  productRooms: [],
  isLoading: false,
  total: 0,
  error: false,
};

export default function(state = initState, action) {
  switch (action.type) {
    case CREATE_PROOMS_REQUEST:
    case FETCH_EXIST_PROOMS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CREATE_PROOMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_EXIST_PROOMS_SUCCESS:
      return {
        ...state,

        error: false,
      };

    case ADD_PROOM_SUCCESS:
      return {
        ...state,
      };

    case DELETE_PROOM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        message: action.message,
        error: false,
      };
    }

    case ADD_PROOMS_SUCCESS:
      return {};

    case SAVE_PROOMS_REQUEST:
      return {
        ...state,
      };

    case SAVE_PROOMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
      };

    case ADD_PROOM_FAIL:
    case DELETE_PROOM_FAIL:
    case SAVE_PROOMS_FAIL:
    case FETCH_EXIST_PROOMS_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    }

    case UPDATE_PROOMS_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
}

const fetchData = async (
  url,
  pageSize,
  page,
  sortField = [],
  // eslint-disable-next-line
  filterField = []
) => {
  let sort = null,
    direction = null;

  if (sortField.length) {
    const temp = sortField[0].id.split('.');
    sort = temp[temp.length - 1];
    direction = sortField[0].desc ? 'desc' : 'asc';
  }

  const response = await axios.get(url, {
    params: {
      pageSize,
      sort,
      page,
      direction,
    },
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
  return response;
};

export const fetchProductRooms = (
  projId,
  prodId,
  pmId,
  pageSize,
  page,
  sortField = [],
  filterField = []
) => async dispatch => {
  try {
    dispatch({ type: FETCH_EXIST_PROOMS_REQUEST });
    const response = await fetchData(
      `/api/project/${projId}/product/${prodId}/productRooms/${pmId}`,
      pageSize,
      page,
      sortField,
      filterField
    );
    if (response.data.success) {
      dispatch({
        type: FETCH_EXIST_PROOMS_SUCCESS,
        productRooms: response.data.productRooms,
      });
      return;
    }
    dispatch({
      type: FETCH_EXIST_PROOMS_FAIL,
      message: 'Unable to fetch rooms.',
    });
  } catch (err) {
    dispatch({
      type: FETCH_EXIST_PROOMS_FAIL,
      message: 'Unable to fetch rooms.',
    });
  }
};
/*eslint-disable no-unused-vars */

export const fetchMRandCreatePR = (data, projId, prodId, pmId) => async (
  dispatch,
  getState
) => {};

export const addProductRoom = (
  productRoom,
  projId,
  modelRmId,
  successCB
) => async dispatch => {};

export const updateProductRoom = (
  proom,
  proomId,
  projId
) => async dispatch => {};

export const deleteProductRoom = (productRoom, projId) => async dispatch => {};
/*eslint-enable no-unused-vars */

const byIdSelector = state => state.byId;
const idsOrderSelector = state => state.allIds;

export const getProductRoomsOrdered = createSelector(
  byIdSelector,
  idsOrderSelector,
  //eslint-disable-next-line
  (productRooms, order) => {
    const productRoomsById = _.values(productRooms).map(
      productRoom => productRoom.fields
    );
    const x = productRoomsById.slice().sort((a, b) => {
      if (a.Room_RoomName < b.Room_RoomName) return -1;
      if (a.Room_RoomName > b.Room_RoomName) return 1;
      return 0;
    });
    return x;
  }
);
